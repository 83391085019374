export const EONLogo = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_163_123752)'>
        <path
          d='M17.6847 19.1661V11.2892C17.6847 11.019 17.7319 10.76 17.8179 10.5193C18.1343 9.63383 18.9806 9 19.9754 9C20.9658 9 21.8093 9.62829 22.1289 10.5077C22.2175 10.7516 22.266 11.0148 22.266 11.2892V19.1661C22.266 20.4304 21.2404 21.4553 19.9754 21.4553C18.7103 21.4553 17.6847 20.4304 17.6847 19.1661Z'
          fill='#338CB6'
        />
        <path
          d='M24.4827 11.3387V19.1661C24.4827 21.65 22.4607 23.6707 19.9754 23.6707C17.49 23.6707 15.468 21.6499 15.468 19.1661V11.3631C12.2229 13.0149 10 16.3848 10 20.2738C10 25.7932 14.4772 30.2676 20 30.2676C25.5228 30.2676 30 25.7932 30 20.2738C30 16.3652 27.7545 12.9813 24.4827 11.3387Z'
          fill='#338CB6'
        />
      </g>
      <defs>
        <clipPath id='clip0_163_123752'>
          <rect
            width='20'
            height='21.2676'
            fill='white'
            transform='translate(10 9)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
