'use client';

import type { Role } from '@repo/api-gw-sdk';
import { createContext, type PropsWithChildren } from 'react';

import { useDAL } from '@/data/dal';

import { useUser } from './useUser';

export const RolesContext = createContext<{
  roles: Role[];
  isAuthorized: (scope: string, roleOverride?: Role) => boolean;
} | null>(null);

export const RolesProvider = ({ children }: PropsWithChildren) => {
  const dal = useDAL();

  const { user } = useUser();

  const { body: roles } = dal.roles.get();

  const isAuthorized = (scope: string, roleOverride?: Role) => {
    const [permission, action] = scope.split(':');
    const optionalScopes = [
      scope,
      `${permission}:all`,
      `admin:${action}`,
      'admin:all',
    ];
    return optionalScopes.some((x) =>
      (roleOverride || user?.selectedRole)?.scopes.includes(x)
    );
  };

  return (
    <RolesContext.Provider
      value={{
        roles: roles?.roles || [],
        isAuthorized,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};
