'use client';

import { Box } from '@mui/material';
import type { PropsWithChildren } from 'react';

import useLayoutInit from '@core/hooks/useLayoutInit';
import type { SystemMode } from '@core/types';

import { EONLogo } from '@/components/layout/shared/eonLogo';

type Props = PropsWithChildren<{
  systemMode: SystemMode;
}>;

const BlankLayout = (props: Props) => {
  const { children, systemMode } = props;

  useLayoutInit(systemMode);

  return (
    <Box
      className='h-full w-full flex flex-col'
      sx={() => ({
        background:
          'url(/images/illustrations/auth/clouds-right.svg) calc(0% - 550px) 37% / 550px auto no-repeat, url(/images/illustrations/auth/cloud-left.svg) calc(100% + 400px) 15% / 400px auto no-repeat, var(--mui-palette-background-pageGradient)',
        animation: 'clouds 180s linear infinite',
        animationDelay: '-110s',
        '@keyframes clouds': {
          '0%': {
            backgroundPosition:
              'calc(0% - 550px) 47%, calc(100% + 800px) 15%, bottom right, bottom left, top left, top right',
          },
          '100%': {
            backgroundPosition:
              'calc(100% + 550px) 47%, calc(0% - 800px) 15%, bottom right, bottom left, top left, top right',
          },
        },
      })}
    >
      {children}
      <Box className='w-full absolute top-0 left-0 flex justify-between content-center px-[40px] pt-[40px]'>
        <EONLogo />
        <a
          href=''
          target='_blank'
          rel='noreferrer'
          className='flex content-center'
        >
          About Eon
          <i className='material-symbols-open-in-new w-[20px] h-[20px] ml-[8px]'></i>
        </a>
      </Box>
      <Box className='w-full absolute bottom-0 left-0 px-[40px] pb-[45px]'>
        © 2024 Eon. All rights reserved.
      </Box>
    </Box>
  );
};

export default BlankLayout;
